import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Mid of Q2 2023",
      title: "Conceito",
      info: [
        "Prova de conceito",
        "Estruturando time",
      ],
    },
    {
      roadmapTitle: "Early Q4 2023",
      title: "Criação do MVP",
      info: [
        "Estruturação de negócios",
        "Criação de whitelist",
      ],
    },
    {
      roadmapTitle: "Mid of Q1 2024",
      title: "Parcerias e Expansão",
      info: [
        "Buscar parcerias estrategicas",
        "Melhorar eficiência operacional",
      ],
    },

    {
      roadmapTitle: "Mid of Q2 2024",
      title: "Engajamento e Governança",
      info: [
        "Fortalecer o envolvimento da comunidade",
        "Estabeleça uma estrutura de governança",
      ],
    },
    {
      roadmapTitle: "Mid of Q3 2024",
      title: "Integração de IA para análise",
      info: [
        "Aprimoramento da análise de dados",
        "Módulo de Análise Preditiva",
      ],
    },
    {
      roadmapTitle: "End of Q4 2024",
      title: "Integrações de terceiros",
      info: [
        "Parceria com Serviços Externos",
        "Desenvolvimento de API para Integração",
      ],
    },
    {
      roadmapTitle: "Begin of Q1 2025",
      title: "Expansão da distribuição",
      info: [
        "Criar canal sólido de distribuição",
        "Automatizar operacional",
      ],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Nosso Roadmap</span>
              <h2 className="title">
                <span>Credit Ledger</span> <br /> Plano de execução
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
