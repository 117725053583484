import React from "react";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";

const chartImg = "https://ik.imagekit.io/abkvohwfl/platform/Purple%20Blue%20Modern%20Pie%20Chart%20Analysis%20Graph_TVYLo21iN.png"

const Sales = () => {
  const chart_info_list = [
    "Notas fiscais: 62%",
    "Cartões de crédito: 25%",
    "Duplicatas: 13%",
  ];

  return (
    <section id="sales" className="chart-area chart-bg ">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeInRight" data-wow-delay=".2s">
                <h4>
                  Alocação por ativos
                </h4>
                <p>
                  Quais ativos mais tokenizamos?
                </p>
                <img src={chartImg} alt="" />
                <ul>
                  {chart_info_list.map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-10">
              <div
                className="chart-wrap wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <h4>
                  Como fazemos isso?
                </h4>
                <ul className="newUl">
                  <li>
                    <h5>
                      1 - Recebemos toda a sua documentação
                    </h5>
                    <div>
                      <p>
                        Validamos os documentos de lastro e risco.
                      </p>
                      <p>
                        Verificamos as garantias e prazos.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5>
                      2 - Criamos uma emissão única pra você
                    </h5>
                    <div>
                      <p>
                        Lançamos a sua emissão na blockchain.
                      </p>
                      <p>
                        Tokenizamos o recebivel em frações de 10.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5>
                      3 - Criamos a sua carteira de auto custódia
                    </h5>
                    <div>
                      <p>
                        Só você tem acesso aos seus ativos tokenizados.
                      </p>
                      <p>
                        Você controla 100% da distribuição dos ativos.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5>
                      4 - Liquidação automática da emissão
                    </h5>
                    <div>
                      <p>
                        Pelo painel, liquide a emissão em poucos cliques.
                      </p>
                      <p>
                        Documentação final completa da emissão.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sales;
